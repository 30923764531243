<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Marking in progress</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Marking in progress
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchOngoingOnlineExam"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select label="Marking priority " dense outlined clearable v-model="search.marking_priority" :items="priorities" item-value="value" item-text="text" :menu-props="{ bottom: true, offsetY: true }"  v-on:keyup.enter="searchOngoingOnlineExam">

                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchOngoingOnlineExam"
                         class="mt-1 btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Candidate Name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Exam start date/time</th>

                  <th class="px-3">Priority</th>
                  <th class="px-3">Time left for Marking</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in in_progress_online_exams" :key="index">
                    <td class="px-3">
                      <a   class="font-weight-bolder">
                        {{item.full_name}}
                      </a>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.exam_key}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.product_name}}
                      </div>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 font-weight-medium text-hover-primary mb-1 font-size-sm">
                        {{item.start_time_format ? item.start_time_format : 'NA'}}
                      </a>
                    </td>
                    <td>
                      <span class="mb-1" v-html="item.marking_priority_badge">

                      </span>
                    </td>
                     <td class="" style="">
                       <div v-if="item.finish_time=='Time Elapsed'">
                        {{item.finish_time}}
                       </div>
                        <div v-else>
                          <countdown :time="item.remaining_time_milli">
                            <template slot-scope="props">Time Remaining：<span class="font-weight-medium subtitle-1" v-if="item.remaining_days_flag">{{ props.days }} days  ,</span>  <span class="font-weight-medium subtitle-1">{{ props.hours }} hours</span> , <span class="font-weight-medium subtitle-1">{{ props.minutes }} minutes</span> , <span class="font-weight-medium subtitle-1">{{ props.seconds }} seconds</span> .</template>
                          </countdown>
                        </div>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">


                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openDialog(item)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fas fa-user</v-icon>
                                </span>
                                <span class="navi-text">Void Exam</span>
                              </a>
                              <a @click="updateOnlineExamResult(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fa-solid fa-list-check</v-icon>
                                </span>
                                <span class="navi-text">Unassign OnlineMarker</span>
                              </a>
                              <a @click="openIncreaseTime(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fa-solid fa-list-check</v-icon>
                                </span>
                                <span class="navi-text">Increase Time</span>
                              </a>
                            </b-dropdown-text>



                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="in_progress_online_exams.length == 0">
                    <td colspan="7" class="text-center px-3">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="in_progress_online_exams.length > 0"
                            class="pull-right mt-7"
                            @input="getAllOngoingOfficialExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Void Exam</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col md="12">
                <p class="font-weight-medium h6">
                  Candidate Full name : {{this.ongoing_online_exam.full_name}}
                </p>
                <p class="font-weight-medium h6">
                  Candidate Enrolment key : {{this.ongoing_online_exam.exam_key}}
                </p>
              </v-col>
              <v-col md="12">
                <v-textarea label="Remarks" dense outlined v-model="remarks" :error="$v.remarks.$error"></v-textarea>
                <span class="text-danger" v-if="$v.remarks.$error">This information is required</span>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btn btn-primary" dark medium @click="updateVoidExam()" :loading="loading">
            Save
          </v-btn>
          <v-btn color="btn btn-standard" dark medium
                 @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="time_dialog" max-width="700" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Increase time</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col md="12">
                <v-select label="Time to increase" dense outlined v-model="date_time" :items="time_types" item-text="text" item-value="value" :error="$v.date_time.$error"></v-select>
                <span class="text-danger" v-if="$v.date_time.$error">This information is required</span>

              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btn btn-primary" dark medium @click="updateOnlineExamResultTime" :loading="loading">
            Save
          </v-btn>
          <v-btn color="btn btn-standard" dark medium
                 @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="assign_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";
import CandidateService from "@/services/candidate/CandidateService";
import {required,requiredIf} from "vuelidate/lib/validators";

const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  validations: {
    remarks:{
      required: requiredIf(function (nestedModel){
        return this.dialog;
      }),
    },
    date_time:{
      required: requiredIf(function (nestedModel){
        return this.time_dialog;
      }),
    },
  },

  data(){
    return{
      search:{
        exam_key:'',
        marking_priority:'',
      },
      isLoading:false,
      in_progress_online_exams:[],
      dialog:false,
      time_dialog:false,
      loading:false,
      ongoing_online_exam:{},
      remarks:'',
      total: null,
      perPage: null,
      page: null,
      priorities:[
        {text:'High',value:'high'},
        {text:'Normal',value:'normal'},
      ],
      assign_overlay:false,
      date_time:'',
      result_id:'',
      time_types:[
        {text:'1 Day',value:'1'},
        {text:'2 Day',value:'2'} ,
        {text:'3 Day',value:'3'} ,
        {text:'4 Day',value:'4'} ,
      ],

    }
  },
  methods:{
    getAllOngoingOfficialExams(){
      this.isLoading=true;
      onlineExamResult
          .getAllInProgressOnlineExamResult(this.search, this.page)
          .then(response => {
            this.in_progress_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchOngoingOnlineExam(){
      this.getAllOngoingOfficialExams();
    },
    closeDialog(){
      this.dialog=false;
      this.time_dialog=false;
      this.ongoing_online_exams={};
      this.result_id=null;
      this.date_time=null;
    },
    openDialog(item){
      this.remarks='';
      this.ongoing_online_exam=item;
      this.dialog=true;
    },
    updateVoidExam(){
      this.assign_overlay=true;
      this.dialog=true;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        let data = {};
        data['candidate_id'] = this.ongoing_online_exam.candidate_id;
        data['remarks'] = this.remarks;
        onlineExamMarker
            .updateOnlineMarkerVoid(data)
            .then(response => {
              this.closeDialog();
            })
            .catch((err) => {

            })
            .finally(() => {
              this.loading = false;
              this.dialog=false;
              this.assign_overlay=false;
            });
      }
    },
    openIncreaseTime(resultId){
        this.time_dialog=true;
        this.result_id=resultId;
    },
    updateOnlineExamResult(resultId){

      this.assign_overlay=true;
        let data = {
          'marked_by': null,
          'marking_locked':0,
          'marking_priority':'normal',
          'marking_lock_start_time':null,
        }
        onlineExamResult
            .update(resultId, data)
            .then(response => {
              this.getAllOngoingOfficialExams();
              this.$snotify.success("Exam marking assigned to marker ");
              this.closeDialog();
            })
            .catch((err) => {

            })
            .finally(() => {
              this.isLoading = false;
              this.assign_overlay=false;
            });


    },
    updateOnlineExamResultTime(){

      this.time_dialog=true;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {

        onlineExamResult
            .update(this.result_id, {'date_time':this.date_time})
            .then(response => {
              this.getAllOngoingOfficialExams();
              this.$snotify.success("Increase Time Successfully ");
              this.closeDialog();

            })
            .catch((err) => {

            })
            .finally(() => {

              this.assign_overlay = false;
              this.time_dialog = false;
            });
      }
    },
  },
  mounted() {
    this.getAllOngoingOfficialExams();
  }
}
</script>